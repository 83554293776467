var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.approvalFlow
        ? _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("ApprovalFlow.DetailsCardTitle", {
                  id: _vm.approvalFlow.id,
                }),
                "action-refresh": "",
                "show-loading": _vm.fetching,
              },
              on: { refresh: _vm.getData },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "approval-flow-name",
                          type: "label",
                          label: _vm.$t("Fluxo"),
                        },
                        model: {
                          value: _vm.approvalFlow.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.approvalFlow, "name", $$v)
                          },
                          expression: "approvalFlow.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _vm.approvalObject
                        ? _c("FormulateInput", {
                            attrs: {
                              id: "approval-flow-object",
                              type: "label",
                              label: _vm.$t("Objeto de aprovação"),
                              "router-to": _vm.approvalObjectRoute,
                            },
                            model: {
                              value: _vm.approvalObject,
                              callback: function ($$v) {
                                _vm.approvalObject = $$v
                              },
                              expression: "approvalObject",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "approval-flow-created-by",
                          type: "label",
                          label: _vm.$t("Solicitante"),
                        },
                        model: {
                          value: _vm.approvalFlow.createdBy,
                          callback: function ($$v) {
                            _vm.$set(_vm.approvalFlow, "createdBy", $$v)
                          },
                          expression: "approvalFlow.createdBy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          value: _vm.approvalFlow.status,
                          type: "label",
                          label: _vm.$t("Status"),
                          "is-badge": "",
                          options: _vm.approvalFlowStatusOptions,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          value: _vm.approvalFlow.createdDate,
                          type: "label",
                          label: _vm.$t("Criado em"),
                          filter: "datetime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { fill: "" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("Fluxo") } },
                            _vm._l(_vm.approvalFlow.steps, function (step) {
                              return _c(
                                "div",
                                { key: step.id, staticClass: "col-md-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "step" },
                                    [
                                      _c(
                                        "h3",
                                        [
                                          _c("approval-flow-status", {
                                            attrs: { status: step.status },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "ApprovalFlow.StepTitle",
                                                  { sequence: step.sequence }
                                                )
                                              ) +
                                              " - " +
                                              _vm._s(step.name) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _vm._l(
                                            step.approvalGroups,
                                            function (approvalGroup) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: approvalGroup.id,
                                                  attrs: { md: "4" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "approval-group",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        [
                                                          _c(
                                                            "approval-flow-status",
                                                            {
                                                              attrs: {
                                                                status:
                                                                  approvalGroup.status,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                approvalGroup
                                                                  .approvalGroup
                                                                  .name
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._l(
                                                        approvalGroup.approvals,
                                                        function (approval) {
                                                          return _c(
                                                            "p",
                                                            {
                                                              key: approval.id,
                                                            },
                                                            [
                                                              _c(
                                                                "approval-flow-status",
                                                                {
                                                                  attrs: {
                                                                    status:
                                                                      approval.status,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    approval.responsibleUserName
                                                                  ) +
                                                                  " "
                                                              ),
                                                              approval.approvalDate
                                                                ? _c("em", [
                                                                    _vm._v(
                                                                      "(" +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "datetime"
                                                                          )(
                                                                            approval.approvalDate
                                                                          )
                                                                        ) +
                                                                        ")"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              approval.observation
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ml-25",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            approval.observation
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          step.approvalType == "Owner"
                                            ? _c(
                                                "b-col",
                                                {
                                                  key: step.id,
                                                  attrs: { md: "4" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "approval-group",
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        [
                                                          _c(
                                                            "approval-flow-status",
                                                            {
                                                              attrs: {
                                                                status:
                                                                  step.status,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " Solicitante "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "p",
                                                        [
                                                          _c(
                                                            "approval-flow-status",
                                                            {
                                                              attrs: {
                                                                status:
                                                                  step
                                                                    .approvalFlowTransactionApproval
                                                                    .status,
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                step
                                                                  .approvalFlowTransactionApproval
                                                                  .responsibleUserName
                                                              ) +
                                                              " "
                                                          ),
                                                          step
                                                            .approvalFlowTransactionApproval
                                                            .approvalDate
                                                            ? _c("em", [
                                                                _vm._v(
                                                                  "(" +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "datetime"
                                                                      )(
                                                                        step
                                                                          .approvalFlowTransactionApproval
                                                                          .approvalDate
                                                                      )
                                                                    ) +
                                                                    ")"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          step
                                                            .approvalFlowTransactionApproval
                                                            .observation
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "ml-25",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        step
                                                                          .approvalFlowTransactionApproval
                                                                          .observation
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      step.documents
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                _vm._l(
                                                  step.documents,
                                                  function (document) {
                                                    return _c(
                                                      "p",
                                                      { key: document.id },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "nav-link",
                                                            attrs: {
                                                              href: document.url,
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _c("b-img", {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                src: _vm.getIconDocument(
                                                                  document.url
                                                                ),
                                                                height: "auto",
                                                                width: "20",
                                                              },
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  document.name
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "b-tab",
                            {
                              attrs: { title: _vm.$t("Histórico") },
                              on: {
                                click: function ($event) {
                                  return _vm.loadEvents()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "step" },
                                [
                                  _c(
                                    "app-timeline",
                                    _vm._l(_vm.events, function (item) {
                                      return _c("app-timeline-item", {
                                        key: item.id,
                                        attrs: {
                                          title: item.description,
                                          subtitle: item.createdBy,
                                          time: _vm._f("datetime")(
                                            item.createdDate
                                          ),
                                          variant: _vm.getStatusIcon(
                                            item.status
                                          ),
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("e-modal-approval-flow-action", {
        ref: "modalApproval",
        on: {
          "after-confirm": function ($event) {
            return _vm.getData()
          },
        },
      }),
      _vm.$can("Update", "ApprovalFlow")
        ? _c("fab", {
            attrs: {
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
            },
            on: {
              approve: function ($event) {
                return _vm.openActionModal("Approve")
              },
              disapprove: function ($event) {
                return _vm.openActionModal("Disapprove")
              },
              "ask-changes": function ($event) {
                return _vm.openActionModal("AskChanges")
              },
              cancel: function ($event) {
                return _vm.cancel()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }