<template>
  <b-icon
    :icon="args.icon"
    :variant="args.variant"
    :title="args.title"
  />
</template>
<script>
import { BIcon } from 'bootstrap-vue'

export default {
  components: {
    BIcon,
  },
  mixins: [],
  props: {
    status: {
      type: String,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      profiles: [],
    }
  },
  computed: {
    args() {
      switch (this.status) {
        case 'Approved':
          return {
            icon: 'check-circle-fill',
            variant: 'success',
            title: this.$t('Aprovado'),
          }
        case 'Disapproved':
          return {
            icon: 'x-circle-fill',
            variant: 'danger',
            title: this.$t('Reprovado'),
          }
        case 'Holding':
        case 'InProgress':
          return {
            icon: 'clock-fill',
            variant: 'none',
            title: this.$t('Aguardando'),
          }
        case 'ChangesRequested':
          return {
            icon: 'question-circle-fill',
            variant: 'warning',
            title: this.$t('Alterações solicitadas'),
          }
        default:
          return null
      }
    },
    // variant() {
    //   switch (this.status) {
    //     case 'Approved':
    //       return 'success'
    //     case 'Disapproved':
    //       return 'danger'
    //     case 'Holding':
    //     case 'InProgress':
    //       return 'none'
    //     case 'ChangesRequested':
    //       return 'warning'
    //     default:
    //       return null
    //   }
    // },
  },
  // async mounted() {
  // },
  methods: {
    // async validate() {
    //   this.$refs.form.showErrors()
    //   return this.$refs.form.isValid
    // },
  },
}
</script>
